.form-control{
	border: 2px solid $color-yellow;
	border-radius: 13px;
	padding: 24px 13px;
}

.custom-checkbox{
	width: 21px;
	height: 21px;
	display: block;
	border: 2px solid $color-yellow;
	border-radius: 6px;
	cursor: pointer;
}

input[type=checkbox]:checked + .custom-checkbox{
	background: $color-yellow;
}

.text-help{
	color: red;
}
.invalid .text-help{
	display: block !important;
}