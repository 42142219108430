body{
	nav{
		position: fixed;
		background: #fff;
		left: 0;
		top: 0;
		width: 100%;
		z-index: 999;
		padding: 10px 0;
		box-shadow: 0 3px 14px 0 rgba(0,0,0,0.3);
		font-size: 16px;

		@media(max-width: 768px){
			font-size: 14px;
		}

		img{
			max-width: 120px;
		}

		ul{
			padding: 0;
			margin: 0;
			list-style: none;
			display: flex;
			align-items: center;
		}

		li{
			padding: 0 20px;
		}
	}
}
