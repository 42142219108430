@import url('https://fonts.googleapis.com/css?family=Comfortaa:400,600,700|Open+Sans:400,700&display=swap&subset=latin-ext');


$font-primary: 'Open Sans', sans-serif;
$font-extra: 'Comfortaa', cursive;

$color-text: #060606;
$color-blue: #1F2245;
$color-yellow: #F7C752;


@mixin font-primary($weight: false, $size: false) {
	font-family: $font-primary;

	$weights: ('regular' : 400, 'bold' : 700);

	@if $weight { font-weight: map_get($weights, $weight); }
	@if $size { font-size: $size; }
}

@mixin font-extra($weight: false, $size: false) {
	font-family: $font-extra;

	$weights: ('regular' : 400, 'semibold' : 600, 'bold' : 700);

	@if $weight { font-weight: map_get($weights, $weight); }
	@if $size { font-size: $size; }
}

.font-sm{
	font-size: 18px;

	@media(max-width: 1600px){
	    font-size: 14px;
	}
}
.font-xs{
	font-size: 13px;

	@media(max-width: 1600px){
		font-size: 10px;
	}
}

.rotate-180{
	transform: rotate(180deg);
}

@mixin reset() {
	background: none;
	color: initial;
	border: none;
	padding: 0;
	margin: 0;
}

.color-text{
	color: $color-text !important;
}
.color-white{
	color: #fff !important;
}
.color-yellow{
	color: $color-yellow;
}
.color-blue{
	color: $color-blue;
}
.bg-blue{
	background-color: $color-blue;
}
.border-yellow{
	border: 3px solid $color-yellow;
}

 $grid-breakpoints: (
 		xs: 0,
 		sm: 576px,
 		md: 768px,
 		lg: 992px,
 		xl: 1200px,
 		xxl: 1600px
 );

 $container-max-widths: (
 		sm: 540px,
 		md: 720px,
 		lg: 960px,
 		xl: 1140px,
 		xxl: 1530px
 );

$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge((
		0: 0,
		1: ($spacer * .25),
		2: ($spacer * .5),
		3: $spacer,
		4: ($spacer * 1.5),
		5: ($spacer * 3),
		6: ($spacer * 5),
		7: ($spacer * 8)
), $spacers);



// *{
//     box-shadow: 0px 0px 0px 1px rgba(red, .2);
// }
// .container{
//     box-shadow: 0px 0px 0px 1px rgba(green, .4);
// }
// .row{
//     box-shadow: 0px 0px 0px 1px rgba(blue, .2);
// }
