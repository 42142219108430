body{
	@include font-primary('regular', 20px);

	@media(max-width: 1600px){
		font-size: 16px;
	}
}

.section-title{
	@include font-extra('bold', 31px);
	color: $color-text;
	display: inline-block;

	&:after{
		content: '';
		height: 3px;
		width: calc(100% + 20px);
		display: block;
		background: $color-yellow;
		margin-top: 6px;
	}

	@media(max-width: 1600px){
		font-size: 25px;
	}
}

.scroll-btn{
	color: #fff;
	text-align: center;
	@include font-extra('bold', 15px);

	position: absolute;
	bottom: 60px;
	left: 50%;
	transform: translateX(-50%);


	@keyframes scroll {
		0% {
			transform: translateY(-15px);
		}
		50% {
			transform: translateY(0px);
		}
		100% {
			transform: translateY(-15px);
		}
	}

	img{
		margin-top: 20px;
		animation-name: scroll;
		animation-duration: 3s;
		animation-iteration-count: infinite;
		width: 20px;
	}
}

footer{
	//background: url("../src/img/footer-bg.jpg") no-repeat;
	background-color: $color-blue;
	//padding-top: 200px;
}


.slick-slide {
	&>div{
		text-align: center;
	}
	img {
		display: inline-block;
		text-align: center;
	}
}

#cookies{
	position: fixed;
	bottom: 0;
	width: 100%;
	z-index: 99999999;
	color: #fff;
	font-size: 15px;
	background-color: rgba($color-blue, .63);
	padding: 15px 0;

	button{
		font-size: 17px;
	}
}