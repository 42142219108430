.page-header{
	//background: red;
	background: url("../src/img/head-bg.jpg") no-repeat;
	background-position: bottom center;
	height: 100vh;
	position: relative;

	.logo{
		max-width: 360px;

		@media(max-width: 1600px){
			max-width: 300px;
		}
	}

	h1{
		@include font-extra('regular', 27px);

		@media(max-width: 1600px){
			font-size: 20px;
		}
	}
}