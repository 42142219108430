.btn{
	&:focus {
		box-shadow: none;
	}

	&.btn-primary{
		background: $color-blue;
		border: none;
		@include font-extra('semibold', 27px);
		padding: 14px 62px;
		border-radius: 50px;

		@media(max-width: 1600px){
			font-size: 19px;
		}
	}

	&.btn-secondary{
		color: $color-text;
		background: $color-yellow;
		border: none;
		@include font-extra('semibold', 27px);
		padding: 14px 62px;
		border-radius: 50px;

		@media(max-width: 1600px){
			font-size: 19px;
		}
	}

	&.btn-sm{
		font-size: 19px;

		@media(max-width: 1600px){
			font-size: 15px;
		}
	}
}